import styled from 'styled-components'
import { mq } from 'core/theme'

const ButtonGroup = styled.div`
    @media ${mq.small} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        .Button {
            width: 100%;
            white-space: wrap;
            font-size: 12px;
            padding: 8px 16px;
            border-width: 0.5px 1px;
        }
        .Button:first-of-type {
            border-top: rgb(145, 159, 207) solid 1px;
            border-bottom: rgb(145, 159, 207) solid 0.5px;
        }
        .Button:last-of-type {
            border-top: rgb(145, 159, 207) solid 0.5px;
            border-bottom: rgb(145, 159, 207) solid 1px;
        }
    }
    @media ${mq.mediumLarge} {
        display: inline-flex;
        vertical-align: middle;
        margin-bottom: 16px;
        .Button {
            padding: 10px 24px;
            border-width: 1px 0.5px;
            min-width: 200px;
            font-size: 16px;
        }
        .Button:first-of-type {
            border-left: rgb(145, 159, 207) solid 1px;
            border-right: rgb(145, 159, 207) solid 0.5px;
        }
        .Button:last-of-type {
            border-left: rgb(145, 159, 207) solid 0.5px;
            border-right: rgb(145, 159, 207) solid 1px;
        }
    }
`

export default ButtonGroup
