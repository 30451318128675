import React from 'react'
import ReactGA from 'react-ga4'
import LayoutWrapper from 'core/layout/LayoutWrapper'
import config from 'Config/config.yml'

const { gaUAid } = config

ReactGA.initialize(gaUAid)

export const onRouteUpdate = ({ location }) => {
    const pathName = location.pathname;
    ReactGA.send({ hitType: "pageview", page: pathName, title: pathName });
}

export const wrapPageElement = ({ element, props }) => {
    const { pageContext, ...rest } = props

    return (
        <LayoutWrapper {...rest} pageContext={pageContext}>
            {element}
        </LayoutWrapper>
    )
}
